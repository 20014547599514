import { useEffect } from 'react'

const useTiktokPixel = () => {
  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://analytics.tiktok.com/i18n/pixel/events.js'
    script.async = true
    script.onload = () => {
      if (window.ttq) {
        window.ttq.load('CGIGVSJC77U55TSEMBH0')
        window.ttq.page()
      }
    }
    script.onerror = () => {
      console.error('TikTok Pixel failed to load.')
    }

    document.head.appendChild(script)

    return () => {
      document.head.removeChild(script)
    }
  }, [])
}

export default useTiktokPixel
