import { ReactElement, ReactNode } from 'react'
import '../styles/globals.css'
import type { AppProps } from 'next/app'
import { NextPage } from 'next'
import { SessionProvider } from 'next-auth/react'
import { appWithTranslation } from 'next-i18next'
import { Hydrate, QueryClient } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { RecoilRoot } from 'recoil'
import { ThemeProvider } from 'next-themes'
import { start } from '../lib/bugsnag'
import { fjallaOne, manrope, oswald } from '../common/fonts/fonts'
import { selectedGameState } from '../common/atoms/atoms'
import { selectedProductState } from '../common/atoms/checkout'
import 'swiper/css'
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client'
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'
import { GoogleAnalytics } from '@next/third-parties/google'
import FacebookPixel from '../components/FacebookPixel'
import useTiktokPixel from '../hooks/analytics/useTiktokPixel'
import { PagesTopLoader } from 'nextjs-toploader/pages'

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode
  theme?: string
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

// Start Bugsnag
start()

// Initialize Query Client Outside to Avoid Re-renders
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 60 * 24, // 24 hours
    },
  },
})

// Map Recoil Atoms
const allAtoms = {
  game: selectedGameState,
  product: selectedProductState,
}

function MyApp({
  Component,
  //@ts-ignore
  pageProps: { session, initialRecoilState, ...pageProps },
}: AppPropsWithLayout) {
  // Use TikTok Pixel
  useTiktokPixel()

  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout ?? ((page) => page)

  // Where the magic happens: Used by RecoilRoot to update atoms by key given an
  // initial state object of the form {key: initial_value}
  const initializeRecoilState =
    (initialRecoilState: any) =>
    //@ts-ignore
    ({ set }) => {
      if (initialRecoilState !== undefined && initialRecoilState !== null)
        Object.keys(initialRecoilState).forEach((key) => {
          const value = initialRecoilState[key]
          if (value === undefined || value === null) return
          //@ts-ignore
          const atom = allAtoms[key]
          set(atom, value)
        })
    }

  // Set up Persisted Storage for Query Client
  const persister = createSyncStoragePersister({
    storage: typeof window !== 'undefined' ? window.localStorage : undefined,
    throttleTime: 500,
  })

  // const userID = Cookies.get(UID_COOKIE)

  return (
    <>
      <PagesTopLoader color='#C3FF00' />
      <ThemeProvider
        defaultTheme={'dark'}
        attribute={'class'}
        enableSystem={false}
        enableColorScheme={false}
        forcedTheme={Component.theme || undefined}
      >
        <RecoilRoot initializeState={initializeRecoilState(initialRecoilState)}>
          <SessionProvider
            session={session}
            // refetchInterval={1800} // 30 minutes
            // refetchOnWindowFocus={true}
          >
            <PersistQueryClientProvider
              client={queryClient}
              // @ts-ignore
              persistOptions={{ persister }}
            >
              <Hydrate state={pageProps.dehydratedState}>
                <style jsx global>{`
                  :root {
                    --font-oswald: ${oswald.style.fontFamily};
                    --font-manrope: ${manrope.style.fontFamily};
                    --font-fjallaOne: ${fjallaOne.style.fontFamily};
                  }
                `}</style>

                <GoogleAnalytics gaId='G-X30BS5B3K3' />

                <FacebookPixel />

                {/* <Script
                id='tiktok-pixel'
                strategy='afterInteractive'
                src='https://analytics.tiktok.com/i18n/pixel/events.js'
                onLoad={() => {
                  ;(window as any).ttq = (window as any).ttq || []
                  ;(window as any).ttq.load('CGIGVSJC77U55TSEMBH0')
                  ;(window as any).ttq.page()
                }}
              /> */}

                {/*<Script*/}
                {/*  id='water-init'*/}
                {/*  dangerouslySetInnerHTML={{*/}
                {/*    __html: `*/}
                {/*(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://load.water.tapin.gg/qspgdugs.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-NSPGC2N');`,*/}
                {/*  }}*/}
                {/*/>*/}

                {getLayout(<Component {...pageProps} />)}
              </Hydrate>
              <ReactQueryDevtools initialIsOpen={false} />
            </PersistQueryClientProvider>
          </SessionProvider>
        </RecoilRoot>
      </ThemeProvider>
    </>
  )
}

export default appWithTranslation(MyApp)
